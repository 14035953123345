import { HttpService } from "../HttpService";

export class ManufacturersService extends HttpService {

  fetchManufacturers = async (params) => {
    return await this._$get("/manufacturers", {params: params}).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  fetchManufacturer = async id => {
    return await this._$get(`/manufacturers/${id}/getmanufacturer`).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  fetchManufacturerDealers = async ({id, params}) => {
    return await this._$get(`/manufacturers/${id}/dealers`, {params: params}).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };
  
  fetchManufacturerDealersWithCertInfo = async id => {
    return await this._$get(`/manufacturers/${id}/dealerswithcertinfo`).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  addManufacturer = async payload => {
    return await this._$post("/manufacturers", {body: payload}).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  editManufacturer = async payload => {
    return await this._$post("/manufacturers/edit", {body: payload}).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };  

  fetchUsersByManufacturerRegion = async ({id, search}) => {
    const params = (search) ? {search: search} : null
    return await this._$get(`/manufacturers/${id}/users`, {params: params}).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  changeActiveManufacturerUser = async ({id, userId}) => {
    return await this._$post(`/manufacturers/${id}/user/${userId}`).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  changeManager = async ({id, partnerId}) => {
    return await this._$put(`/manufacturers/changemanager?managerId=${id}&partners=${partnerId}`).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  manufacturerVerify = async () => {
    return await this._$get("/manufacturers/verify").catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  manufacturerData = async () => {
    return await this._$get("/manufacturers/confirmed").catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  manufacturerDataConfirm = async payload => {
    return await this._$put("/manufacturers/confirmed", {body: payload}).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  manufacturerDataWrong = async payload => {
    return await this._$put("/manufacturers/notconfirmed", {body: payload}).catch((err) => console.error(`$ManufacturerService Error::${err}`))
  };

  changeManufacturerData = async ({id, payload}) => {
    return await this._$post(`/manufacturers/${id}/change`, {body: payload}).catch(err => console.log(err));
  };

  // Self Retails Methods
  fetchSelfRetails = async ({id, params}) => {
    return await this._$get(`/manufacturers/${id}/selfretails`, {params: params}).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  fetchSelfRetail = async ({id, retailId}) => {
    return await this._$get(`/manufacturers/${id}/selfretails/${retailId}`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  createSelfRetail = async ({id, payload}) => {
    return await this._$post(`/manufacturers/${id}/selfretails`, {body: payload}).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  createSelfRetailCertify = async ({id, retailId}) => {
    return await this._$post(`/manufacturers/${id}/selfretails/${retailId}/certify`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  updateSelfRetail = async ({id, retailId, payload}) => {
    return await this._$post(`/manufacturers/${id}/selfretails/${retailId}`, {body: payload}).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  changeManufacturerInfo = async (id) => {
    return await this._$put(`/manufacturers/${id}/showmanufacturerinfo`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

}