import RehauStore from "@/store/lib";
import { Messages } from "@/constants";
import { DealersService, ManufacturersService, DealerCertificatesService } from "@/services";

const dealersService = new DealersService();
const manufacturersService = new ManufacturersService();
const dealerCertificatesService = new DealerCertificatesService();

const partners = new RehauStore({
  state: {
    confirmed: false,

    partners: [],
    partnersTotal: null,

    partnerExists: false,
    showAddNewPartnerForm: false,
    partnersByInn: [],

    usersByByPartnerRegion: [],

    partner: {},
    partnerForVerify: {},
    partnerOffices: [],
    partnerOfficesTotal: null,
    partnerCertificates: [],
    partnerCertificatesTotal: null,

    manufacturer: {},

    anotherManufacturerRequest: false,

    certifyReReleaseHistory: []
  }
})
  .action("dealerVerify", {
    serviceFn: dealersService.dealerVerify,
    onSuccess: (state, { data }) => {
      state.confirmed = data.confirmed;
    }
  })
  .action("dealerVerifyDetails", {
    serviceFn: dealersService.dealerVerifyDetails,
    property: "partnerForVerify"
  })
  .action("dealerDataWrong", {
    serviceFn: dealersService.dealerDataWrong,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.MessageSended, {
        root: true
      });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.MessageNotSended, {
        root: true
      });
    }
  })
  .action("dealerDataConfirm", {
    serviceFn: dealersService.dealerDataConfirm,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.RequestConfirmed, {
        root: true
      });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.RequestConfirmed, {
        root: true
      });
    }
  })
  .action("fetchPartners", {
    serviceFn: dealersService.fetchPartners,
    onSuccess: (state, { data }) => {
      state.partners = data.items;
      state.partnersTotal = data.totalItems;
    }
  })
  .action("fetchManufacturerPartners", {
    serviceFn: manufacturersService.fetchManufacturerDealers,
    onSuccess: (state, { data }) => {
      state.partners = data.items;
      state.partnersTotal = data.totalItems;
    }
  })
  .action("fetchManufacturerById", {
    serviceFn: manufacturersService.fetchManufacturer,
    property: "manufacturer"
  })
  .action("addPartner", {
    serviceFn: dealersService.createDealer,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      state.partnerExists = false;
      state.showAddNewPartnerForm = false;
      context.dispatch("showSuccessAlert", Messages.Success.DealerAdded, {
        root: true
      });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, {
        root: true
      });
    }
  })
  .action("fetchPartnersByInn", {
    serviceFn: dealersService.fetchDealersByInn,
    property: "partnersByInn"
  })
  .action("clearDealersByInn", {
    serviceFn: () => {return {ok: true, data: []}},
    onSuccess: (state, {data}) => {
      state.partnersByInn = data;
      state.partnerExists = false;
      state.showAddNewPartnerForm = false;
    }
  })
  .action("addOffice", {
    serviceFn: dealersService.addDealerAddress,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      state.partnerExists = false;
      state.sowAddNewDealerForm = false;
      context.dispatch("showSuccessAlert", Messages.Success.DealerAdded, {
        root: true
      });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", `${error}`, {
        root: true
      });
    }
  })
  .action("changeOffice", {
    serviceFn: dealersService.changeAddress,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.AddressEdited, {
        root: true
      });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.AddressEdited, {
        root: true
      });
    }
  })
  .action("fetchUsersByPartnerRegion", {
    serviceFn: dealersService.fetchUsersByPartnerRegion,
    property: "usersByByPartnerRegion"
  })
  .action("fetchPartner", {
    serviceFn: dealersService.fetchPartner,
    property: "partner"
  })
  .action("certifyPartner", {
    serviceFn: dealerCertificatesService.certifyDealer,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.CertifyDealerRequest, { root: true });
    },
    onError: (state, { context, error }) => {
      context.dispatch("showErrorAlert", error || Messages.Error.CertifyDealerRequest, {
        root: true
      });
    }
  })
  .action("fetchPartnerOffices", {
    serviceFn: dealersService.fetchPartnerAddresses,
    onSuccess: (state, { data }) => {
      console.log(data);
      let arr = data.items.map(item => {
        if (item.hasActiveRequest) {
          item._rowVariant = "success";
        }
        return item;
      });
      console.log(arr);
      state.partnerOffices = arr;
      state.partnerOfficesTotal = data.totalItems;
    }
  })
  .action("fetchAllPartnerOffices", {
    serviceFn: dealersService.fetchAllDealerAddresses,
    onSuccess: (state, { data }) => {
      state.partnerOffices = data.items;
      state.partnerOfficesTotal = data.totalItems;
    }
  })
  .action("fetchManufacturerPartnerOffices", {
    serviceFn: dealersService.fetchManufacturerDealerAddresses,
    onSuccess: (state, { data }) => {
      state.partnerOffices = data;
      state.partnerOfficesTotal = data.length;
    }
  })
  .action("fetchAllPartnerCertificates", {
    serviceFn: dealersService.fetchAllDealerCertificates,
    onSuccess: (state, { data }) => {
      console.log(data);
      state.partnerCertificates = data.items;
      state.partnerCertificatesTotal = data.totalItems;
    }
  })
  .action("fetchManufacturerPartnerCertificates", {
    serviceFn: dealersService.fetchDealerCertificates,
    onSuccess: (state, { data }) => {
      console.log(data);
      state.partnerCertificates = data.items;
      state.partnerCertificatesTotal = data.totalItems;
    }
  })
  .action("dealerCertificateRevocation", {
    serviceFn: dealerCertificatesService.revocationDealer,
    property: "partnerCertificates"
  })
  .action("editPartner", {
    serviceFn: dealersService.editDealer,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.DealerEdited, {
        root: true
      });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.DealerNotEdited, {
        root: true
      });
    }
  })
  .action("adminEditPartner", {
    serviceFn: dealersService.adminEditDealer,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.DealerEdited, {
        root: true
      });
    },
    onError: (state, { error, context }) => {
      context.dispatch("showErrorAlert", error, {
        root: true
      });
    }
  })
  .action("toggleManufacturerInfo", {
    serviceFn: dealersService.changeManufacturerInfo,
    onSuccess: (state, { data, context }) => {
      console.log(data);
      context.dispatch("showSuccessAlert", Messages.Success.DealerEdited, {
        root: true
      });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.DealerNotEdited, {
        root: true
      });
    }
  })
  .action("fetchManufacturerById", {
    serviceFn: manufacturersService.fetchManufacturer,
    property: "manufacturer"
  })
  .action("checkForAnotherManufacturerRequest", {
    serviceFn: dealerCertificatesService.checkForAnotherManufacturerRequest,
    property: "anotherManufacturerRequest"
  })
  .action("changePartnerData", {
    serviceFn: dealersService.changePartnerData,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.DealerEdited, {
        root: true
      });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.DealerEdited, {
        root: true
      });
    }
  })
  .action("changeActivePartnerUser", {
    serviceFn: dealersService.changeActivePartnerUser,
    onSuccess: (state, { context }) => {
      context.dispatch("showSuccessAlert", Messages.Success.DealerEdited, { root: true });
    },
    onError: (state, { context }) => {
      context.dispatch("showErrorAlert", Messages.Error.DealerEdited, { root: true });
    }
  })
  .action("fetchCertifyReReleaseHistory", {
    serviceFn: dealerCertificatesService.fetchCertifyReReleaseHistory,
    property: 'certifyReReleaseHistory',
    onError: (state, {error, context}) => {
      state.certifyReReleaseHistory = []
      context.dispatch("showErrorAlert", error, {root: true})
    }
  })

  // Getters
  .getter("officesDD", state => {
    let res = state.partnerOffices
      .filter(item => !item.hasCertificate)
      .map(item => {
        return { value: item.id, text: item.spotAddress };
      });
    return res;
  })
  .getStore();

export default partners;
