import store from "@/store";

const querystring = require('querystring');
export class HttpService {
  constructor() {
    this._apiBase = process.env.VUE_APP_API;
    this.tokenExpired = false
  }

  _$http = async (url, options = {}) => {
    if (options.params)
      url += `?${querystring.stringify(options.params)}`

    const fetchOptions = {
      method: options.method || 'get',
      headers: options.headers || {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters.getToken
      }
    }
    if (options.body)
      fetchOptions['body'] = JSON.stringify(options.body)

    const response = await fetch(`${this._apiBase}${url}`, fetchOptions);
    console.log(response)
    if (!response.ok) {
      const errorMessage = options.errorMessage || await response.text();
      console.error(errorMessage)
      return { ok: false, errorMessage: errorMessage }
    }
    const data = await response.json()
    console.log(data)
    return { ok: true, data: data }
  }

  _$get = async (url, options = {}) => await this._$http(url, options)
  _$post = async (url, options = {}) => await this._$http(url, Object.assign(options, {method: 'post'}))
  _$put = async (url, options = {}) => await this._$http(url, Object.assign(options, {method: 'put'}))

}
